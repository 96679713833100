import { FormGroup } from '@angular/forms';
import { QatchComponent } from '../qatch.component';
import { QuestionBase } from './types/question-base';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UikitDocumentComponent } from '@hss-m/uikit-common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss']
})
export class DynamicFormQuestionComponent extends QatchComponent{
  @ViewChild(UikitDocumentComponent) uikitDocument: UikitDocumentComponent;
  @Input() question: QuestionBase<string>;
  @Input() form: FormGroup;
  @Input() spacing = 'mt-5';
  @Input() uiTaggingKey: any = null;
  @Output() onChange = new EventEmitter();
  constructor (
    protected override deviceService: DeviceDetectorService,
    protected override authService: AuthService
  ) {
      super(deviceService, authService);
  }

  override ngOnInit(){
    super.ngOnInit();
    this.form.get(this.question.key).valueChanges.subscribe( event => {
      this.onChange.emit(event);
    })
  }

  getAnalyticClass(question: any) {
    return this.uiTaggingKey ? `${this.uiTaggingKey}_${question.key}` : '';
  }

  get isValid() {
    return this.form.controls[this.question.key].valid;
  }

  get field() {
     return this.form.controls[this.question.key]
  }

  get fieldName() {
    return this.question.key
  }

  get isDirty() {
    return this.form.controls[this.question.key].dirty
  }

  get isTouched() {
    return this.form.controls[this.question.key].touched
  }
  
}
