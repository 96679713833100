import { QuestionBase } from '../question-base';
import { IDocumentUploadConfig } from '@hss-m/uikit-common';
import { QuestionBaseConfig } from '../question-base-config';

export class FileUploadQuestion extends QuestionBase<string> {
  override controlType = 'file-upload';
  override settings: Partial<IDocumentUploadConfig> = {
    maxLimit: 1,
    softDelete: false,
  }

  constructor(config: QuestionBaseConfig<string> = {}) {
    super(config);
    if (config.settings) {
      this.settings = {
        ...config.settings
      };
    }
  }
}
